import { useCallback, useState } from "react";

/**
 *
 * Implementation of useLocalStorageState function
 *
 * @param key localStorage key
 * @param initialValue initial value for state
 * @returns a stateful value that's persisted to localStorage and a function to update it.
 */
export function useLocalStorageState(key, initialValue) {
  const valueFromLocalStorage = localStorage.getItem(key);

  const [state, setState] = useState(() =>
    valueFromLocalStorage ? JSON.parse(valueFromLocalStorage) : initialValue
  );

  const _setState = useCallback(
    (newState) => {
      /**
       * If parameter type is a function we provide the previous state as an argument to callback function.
       */
      if (typeof newState === "function" && newState instanceof Function) {
        setState((prev) => {
          const value = newState(prev);

          localStorage.setItem(key, JSON.stringify(value));
          return value;
        });
        return;
      }

      /**
       * Otherwise just set the value provided into the local state and persist it to localStorage.
       */
      setState(newState);

      localStorage.setItem(key, JSON.stringify(newState));
    },
    [setState, key]
  );

  return [state, _setState];
}
