import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

import { getFromStore } from "../../helpers/getFromStore";

const initialState = {
  amounts: null,
  isLoading: false,
  singleAmount: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAmounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAmounts.fulfilled, (state, action) => {
      state.amounts = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAmounts.rejected, (state, action) => {
      state.amounts = [];
      state.isLoading = false;
    });
    builder.addCase(fetchAmountById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAmountById.fulfilled, (state, action) => {
      state.singleAmount = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAmountById.rejected, (state, action) => {
      state.singleAmount = null;
      state.isLoading = false;
    });
  },
});

export const getAmount = async (id) => {
  const response = await getFromStore(
    "payment.amount",
    id,
    async () => await DataService.get(`${API.payment.amount}/${id}`)
  );
  return response.data ?? response;
};

export const getAmounts = async () => {
  const response = await getFromStore(
    "payment.amount",
    undefined,
    async () => await DataService.get(`${API.payment.amount}`),
    true
  );
  return response.data ?? response;
};

export const updateAmount = async (amountData) => {
  amountData.currencyEuro = "Eur";
  amountData.currencyDollar = "Dollar";
  return await DataService.patch(
    `${API.payment.amount}/${amountData.uuid}`,
    amountData
  );
};

export const createAmount = async (amountData) => {
  amountData.currencyEuro = "Eur";
  amountData.currencyDollar = "Dollar";
  return await DataService.post(API.payment.amount, amountData);
};

export const fetchAmounts = createAsyncThunk(
  "Payment/fetchAmounts",
  async () => {
    return await getAmounts();
  }
);

export const fetchAmountById = createAsyncThunk(
  "Payment/fetchAmountById",
  async (argh) => {
    return await getAmount(argh);
  }
);

export const updateAmounts = createAsyncThunk(
  "Payment/updateAmount",
  async (argh) => {
    return await updateAmount(argh);
  }
);

export const PaymentReducer = paymentSlice.reducer;
