import { reduce } from "lodash";

export const buildUrlArgs = (params, commaSeparatedValues) => {
  const elements = reduce(
    params,
    (collection, val, key) => {
      if (typeof val === "string" || typeof val === "number") {
        collection.push(`${key}=${encodeURIComponent(val.toString())}`);
      } else if (typeof val === "boolean") {
        collection.push(`${key}=${val ? "1" : "0"}`);
      } else if (Array.isArray(val)) {
        if (commaSeparatedValues) {
          // With csv, we pass a single query param with comma-separated values
          const allValues = val
            .map((v) => encodeURIComponent(v.toString()))
            .join(",");
          collection.push(`${key}=${allValues}`);
        } else {
          // Without CSV, we pass the same parameter multiple times instead
          val.forEach((v) =>
            collection.push(`${key}=${encodeURIComponent(v.toString())}`)
          );
        }
      }
      return collection;
    },
    []
  );

  if (elements.length === 0) {
    return "";
  }

  return elements.join("&");
};

export const buildUrl = (stem, params, commaSeparatedValues) => {
  const args = buildUrlArgs(params, commaSeparatedValues);

  if (args.length === 0) {
    return stem;
  }

  return `${stem}?${args}`;
};
