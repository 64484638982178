import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Pricing = lazy(() => import('../../container/pages/PricingTable'));
const Maintenance = lazy(() => import('../../container/pages/Maintenance'));
const Search = lazy(() => import('../../container/pages/SearchResult'));
const ComingSoon = lazy(() => import('../../container/pages/ComingSoon'));
const TermsCondition = lazy(() => import('../../container/pages/TermsComditions'));
const BlankPage = lazy(() => import('../../container/pages/BlankPage'));
const ChangeLog = lazy(() => import('../../container/pages/ChangeLog'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="changelog" element={<ChangeLog />} />
      <Route path="Pricing" element={<Pricing />} />
      <Route path="search" element={<Search />} />
      <Route path="starter" element={<BlankPage />} />
      <Route path="comingSoon" element={<ComingSoon />} />
      <Route path="termCondition" element={<TermsCondition />} />
      <Route path="*" element={<NotFound />} />
      <Route path="maintenance" element={<Maintenance />} />
    </Routes>
  );
}

export default PagesRoute;
