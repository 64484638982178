import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { DataService } from "../../config/dataService/dataService";

const initialState = {
  collaborationsLogs: [],
  isLoadingCollaborationsLogs: false,
};

const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCollaborationsLogs.pending, (state) => {
      state.isLoadingCollaborationsLogs = true;
    });
    builder.addCase(getCollaborationsLogs.fulfilled, (state, action) => {
      state.collaborationsLogs = action.payload;
      state.isLoadingCollaborationsLogs = false;
    });

    builder.addCase(getCollaborationsLogs.rejected, (state) => {
      state.collaborationsLogs = [];
      state.isLoadingCollaborationsLogs = false;
    });
  },
});

export const getCollaborationsLogs = createAsyncThunk(
  "collaboration/logs",
  async (values) => {
    const { data } = await DataService.get("/collaboration/logs", values);
    return data;
  }
);

const persistConfig = {
  key: "applogs",
  storage: storage,
};

export const logsReducer = persistReducer(persistConfig, logsSlice.reducer);
