import {
  UilHeadphones,
  UilWindowSection,
  UilDocumentInfo,
  UilSetting,
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import UilEllipsisV from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import propTypes from "prop-types";
import { NavTitle } from "./Style";

import UilUser from "@iconscout/react-unicons/icons/uil-user";
import UilGlobe from "@iconscout/react-unicons/icons/uil-globe";

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  // const dispatch = useDispatch();

  const path = "/admin";

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  // const changeLayout = (mode) => {
  //   dispatch(changeLayoutMode(mode));
  // };

  // const changeLayoutDirection = (rtlMode) => {
  //   if (rtlMode) {
  //     const html = document.querySelector("html");
  //     html.setAttribute("dir", "rtl");
  //   } else {
  //     const html = document.querySelector("html");
  //     html.setAttribute("dir", "ltr");
  //   }
  //   dispatch(changeDirectionMode(rtlMode));
  // };

  // const darkmodeActivated = () => {
  //   document.body.classList.add("dark-mode");
  // };

  // const darkmodeDiactivated = () => {
  //   document.body.classList.remove("dark-mode");
  // };

  const items = [
    // getItem(t("layouts"), "layout", !topMenu && <UilWindowSection />, [
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         darkmodeDiactivated();
    //         changeLayout("lightMode");
    //       }}
    //       to="#"
    //     >
    //       {t("light")} {t("mode")}
    //     </NavLink>,
    //     "light",
    //     null
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         darkmodeActivated();
    //         changeLayout("darkMode");
    //       }}
    //       to="#"
    //     >
    //       {t("dark")} {t("mode")}
    //     </NavLink>,
    //     "dark",
    //     null
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeLayoutDirection(true);
    //       }}
    //       to="#"
    //     >
    //       RTL
    //     </NavLink>,
    //     "rtl",
    //     null
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeLayoutDirection(false);
    //       }}
    //       to="#"
    //     >
    //       LTR
    //     </NavLink>,
    //     "ltr",
    //     null
    //   ),
    // ]),
    getItem("User approvals", "user-approvals", !topMenu && <UilHeadphones />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/list`}>
          Candidates
        </NavLink>,
        "profiles",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/companies/list`}>
          Companies
        </NavLink>,
        "view-profile",
        null
      ),
    ]),
    getItem(
      !topMenu && (
        <NavTitle className="ninjadash-sidebar-nav-title">Admin</NavTitle>
      ),
      "CRUD-title",
      null,
      null,
      "group"
    ),
    getItem("Candidates", "Candidates", !topMenu && <UilUser />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to="/admin/freelancers/view">
          {t("view")} {t("all")}
        </NavLink>,
        "freelancersView",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/freelancers/Add`}>
          {t("add")} {t("new")}
        </NavLink>,
        "freelancersAdd",
        null
      ),
    ]),
    getItem("Companies", "Companies", !topMenu && <UilGlobe />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/all-companies/View`}>
          {t("view")} {t("all")}
        </NavLink>,
        "companiesView",
        null
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/all-companies/ViewLimited`}
        >
          With limited access
        </NavLink>,
        "companiesViewLimited",
        null
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/all-companies/ViewUnlimited`}
        >
          With Unlimited access
        </NavLink>,
        "companiesViewUnlimited",
        null
      ),
    ]),
    getItem("Admins", "Admins", !topMenu && <UilUser />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to="/admin/admins/view">
          {t("view")} {t("all")}
        </NavLink>,
        "adminsView",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/admins/Add`}>
          {t("add")} {t("new")}
        </NavLink>,
        "adminsAdd",
        null
      ),
    ]),
    getItem("Logs", "Logs", <UilDocumentInfo />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to="/admin/logs/collaborations">
          Collaborations
        </NavLink>,
        "logs",
        null
      ),
    ]),
    getItem("Early Access", "Early Access", <UilWindowSection />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to="/admin/early-access">
          {t("view")}
        </NavLink>,
        "earlyAccess",
        null
      ),
    ]),
    getItem("Asset Management", "Asset Management", <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to="/admin/manage/job-positions">
          Job positions
        </NavLink>,
        "job-positions",
        null
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to="/admin/manage/companies-in-platform"
        >
          Companies in our platform
        </NavLink>,
        "companies-in-platform",
        null
      ),
    ]),
    // getItem("Payment", "Payment", !topMenu && <UilUser />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to="/admin/payment/view">
    //       {t("view")} {t("all")}
    //     </NavLink>,
    //     "paymentView",
    //     null
    //   ),
    // ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
