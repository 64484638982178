/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    login: "/auth/login",
    signUp: "/signup",
    forgotPassword: "/auth/forgot",
  },
  freelancer: {
    user: "/user",
    approve: "/user/account-status",
  },
  company: {
    user: "/company",
    approve: "/company/account-status",
    limitedCompanies: "company/limited-views",
    unlimitedCompanies: "company/un-limited",
    updateMaxProfileViews: "company/profile-visit-limit",
  },
  admin: {
    users: "/user/admins",
    user: "/user",
    create: "/user/create-admin",
  },
  payment: {
    amount: "/amount",
  },
  earlyAccess: {
    codes: "/early-access",
    statistics: "/early-access/statistics",
    create: "/early-access",
  },
};

export { API };
