import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

import { getFromStore } from "../../helpers/getFromStore";

const initialState = {
  admins: null,
  isLoading: false,
  singleAdmin: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdmins.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAdmins.fulfilled, (state, action) => {
      state.admins = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAdmins.rejected, (state, action) => {
      state.admins = [];
      state.isLoading = false;
    });
    builder.addCase(fetchAdminById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAdminById.fulfilled, (state, action) => {
      state.singleAdmin = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAdminById.rejected, (state, action) => {
      state.singleAdmin = null;
      state.isLoading = false;
    });
  },
});

export const getAdmin = async (id) => {
  const response = await getFromStore(
    "admins.admin",
    id,
    async () => await DataService.get(`${API.admin.user}/${id}`)
  );
  return response.data ?? response;
};

export const getAdmins = async (payload) => {
  const response = await getFromStore(
    "admins.admin",
    undefined,
    async () => await DataService.get(API.admin.users, payload)
  );
  return response.data ?? response;
};

export const updateAdmin = async (adminData) => {
  return await DataService.patch(
    `${API.admin.user}/${adminData.uuid}`,
    adminData
  );
};

export const deleteAdmin = async (id) => {
  return await DataService.delete(`${API.admin.user}/${id}`);
};

export const createAdmin = async (adminData) => {
  adminData.accountStatus = "Verified";
  return await DataService.post(API.admin.create, adminData);
};

export const fetchAdmins = createAsyncThunk(
  "Admin/fetchAdmin",
  async (argh) => {
    return await getAdmins(argh);
  }
);

export const fetchAdminById = createAsyncThunk(
  "Admin/fetchAdminById",
  async (argh) => {
    return await getAdmin(argh);
  }
);

export const updateAdmins = createAsyncThunk(
  "Admin/updateAdmin",
  async (argh) => {
    return await updateAdmin(argh);
  }
);

export const AdminReducer = adminSlice.reducer;
