import axios from "axios";
import { getItem } from "../../utility/localStorageControl";
import store from "../../redux/store";
import { logout } from "../../redux/authentication/reducers";
import { buildUrl } from "../../helpers/buildUrl";

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/api`;

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem("access_token")}`,
    "Content-Type": "application/json",
  },
});

class DataService {
  static get(path, input) {
    const url =
      typeof input === "object"
        ? buildUrl(path ?? "", input)
        : path ?? "" + input;

    return client.request({
      method: "GET",
      url,
    });
  }

  static post(path, data, optionalHeader = {}) {
    return client.request({
      method: "POST",
      url: path,
      data: data,
      headers: { ...optionalHeader },
    });
  }

  static patch(path, data) {
    return client.request({
      method: "PATCH",
      url: path,
      data,
    });
  }

  static put(path, data) {
    return client.request({
      method: "PUT",
      url: path,
      data: data ? JSON.stringify(data) : undefined,
    });
  }

  static delete(path) {
    return client.request({
      method: "DELETE",
      url: path,
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem("access_token")}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    if (response) {
      if (response.status === 401 || response.status === 403) {
        store.dispatch(logout());
        return;
      }
      throw Error(response.message);
    }
  }
);
export { DataService };
