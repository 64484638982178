import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { DataService } from "../../config/dataService/dataService";

const initialState = {
  jobs: [],
  isLoading: false,
};

const jobsSlice = createSlice({
  name: "availableJobs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getJobsList.fulfilled, (state, action) => {
      state.jobs = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getJobsList.rejected, (state) => {
      state.jobs = [];
      state.isLoading = false;
    });
  },
});

export const getJobsList = createAsyncThunk("jobs/list", async () => {
  const { data } = await DataService.get("/job");
  return data;
});

export const createJobPosition = async (title) => {
  const { data } = await DataService.post("/job", { title });
  return data;
};

const persistConfig = {
  key: "availableJobs",
  storage: storage,
};

export const jobsListReducer = persistReducer(persistConfig, jobsSlice.reducer);
