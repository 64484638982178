import React, { useEffect, useState } from "react";
import { RecordViewWrapper } from "../../crud/companies/style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Col, Input, Row, Spin, Table } from "antd";
import { Cards } from "../../../components/cards/frame/cards-frame";
import FeatherIcon from "feather-icons-react";
import { Button } from "../../../components/buttons/buttons";
import { ModalStyled } from "../../../components/modals/styled";
import { useDispatch } from "react-redux";
import { createJobPosition, getJobsList } from "../../../redux/jobPositions";
import { useSelector } from "react-redux";
import { DataService } from "../../../config/dataService/dataService";
import { toast } from "react-toastify";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: "right",
  },
];
export const JobPositions = () => {
  const dispatch = useDispatch();
  const { jobs, isLoading } = useSelector((state) => state.jobPositions);

  useEffect(() => {
    dispatch(getJobsList());
  }, [dispatch]);

  const [job, setJob] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const generateNewJobPosition = async () => {
    if (!job) {
      return;
    }

    try {
      await createJobPosition(job);
      dispatch(getJobsList());
      toast.success("Job position created successfully");
      closeModal();
    } catch (e) {
      toast.error("Problem while creating job");
    }
  };

  const deleteJobPosition = (uuid) => async () => {
    if (window.confirm("Are you sure you want to delete this job position?")) {
      try {
        await DataService.delete(`/job/${uuid}`);
        dispatch(getJobsList());
        toast.success("Job deleted successfully");
      } catch (e) {
        toast.error("Problem while deleting job");
      }
    }
  };

  const rows =
    jobs.length > 0
      ? jobs.map((job) => {
          const { title, uuid } = job;

          return {
            title,
            actions: (
              <Button
                className="btn-add_new"
                size="default"
                type="primary"
                onClick={deleteJobPosition(uuid)}
              >
                <FeatherIcon icon="trash" size={20} />
                Delete
              </Button>
            ),
          };
        })
      : [];

  return (
    <RecordViewWrapper>
      <PageHeader
        className="ninjadash-page-header-main"
        routes={[]}
        buttons={[
          <Button
            className="btn-add_new"
            size="default"
            key="1"
            type="primary"
            onClick={openModal}
          >
            <FeatherIcon icon="plus" size={20} />
            <span>Add new job position</span>
          </Button>,
        ]}
        ghost
        title="Job positions"
      />
      <Main>
        <Row gutter={15}>
          <Col className="w-100" md={24}>
            <Cards headless>
              {isLoading ? (
                <div className="spin">
                  <Spin />
                </div>
              ) : (
                <div>
                  <TableWrapper className="table-responsive">
                    <Table
                      dataSource={rows}
                      columns={columns}
                      pagination={false}
                    />
                  </TableWrapper>
                </div>
              )}
            </Cards>
          </Col>
        </Row>
      </Main>
      <ModalStyled
        title="Add new code"
        open={modalOpen}
        okText="Submit"
        onCancel={() => {
          setJob("");
          closeModal();
        }}
        onOk={generateNewJobPosition}
      >
        <p>To create new job position, enter name and click submit</p>
        <Input
          placeholder="Username"
          value={job}
          onChange={(e) => setJob(e.currentTarget.value)}
        />
      </ModalStyled>
    </RecordViewWrapper>
  );
};
