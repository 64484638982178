import { useLocalStorageState } from "./useLocalStorageState";

/**
 *
 * @param FilterOptions:
 *         initialValues - the initial values to use for the filters
 * 		   key - key for localstorage
 *
 * @returns Object with the following properties:
 *      values - the current values of the filters
 *      setFilters - a function to set the values of the filters
 *      setFilter - a function to set a single filter
 *      reset - a function to reset the filters to their initial values
 */

export default function useFilters({ key, initialValues }) {
  const [filters, setFilters] = useLocalStorageState(key, initialValues);

  const setFilter = (f, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [f]: value }));
  };

  const _setFilters = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const reset = () => {
    setFilters(initialValues);
  };

  return {
    values: filters,
    setFilters: _setFilters,
    setFilter,
    reset,
  };
}
