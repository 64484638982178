import { Col, Row, Spin, Table } from "antd";
import React, { useEffect } from "react";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { RecordViewWrapper } from "../../crud/admins/style";
import { useDispatch } from "react-redux";
import { getCollaborationsLogs } from "../../../redux/logs";
import { useSelector } from "react-redux";
import useFilters from "../../../hooks/useFilters";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const columns = [
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    key: "timestamp",
    align: "left",
  },
];
const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE;

export const CollaborationsLogs = () => {
  const dispatch = useDispatch();
  const { collaborationsLogs, isLoadingCollaborationsLogs } = useSelector(
    (state) => state.logs
  );

  const { values, setFilter } = useFilters({
    key: "collaborations-logs",
    initialValues: { page: 1, limit: 10 },
  });

  useEffect(() => {
    dispatch(getCollaborationsLogs(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const rows =
    collaborationsLogs?.data?.length > 0
      ? collaborationsLogs.data.map((log) => {
          return {
            key: log.uuid,
            timestamp: new Date(log.created_at).toLocaleString(),
            action: renderActionLog(log),
          };
        })
      : [];

  return (
    <RecordViewWrapper>
      <PageHeader
        className="ninjadash-page-header-main"
        ghost
        title="Collaborations logs"
      />
      <Main>
        <Row gutter={15}>
          <Col className="w-100" md={24}>
            <Cards headless>
              {isLoadingCollaborationsLogs ? (
                <div className="spin">
                  <Spin />
                </div>
              ) : (
                <div>
                  <TableWrapper className="table-responsive">
                    <Table
                      dataSource={rows}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 10,
                        pageSize: 10,
                        onShowSizeChange: (_, size) => setFilter("limit", size),
                        current: values.page,
                        total:
                          collaborationsLogs?.data?.length > 0
                            ? collaborationsLogs?.totalCount
                            : 0,
                        onChange: (newPage) => setFilter("page", newPage),
                      }}
                    />
                  </TableWrapper>
                </div>
              )}
            </Cards>
          </Col>
        </Row>
      </Main>
    </RecordViewWrapper>
  );
};

const renderActionLog = (log) => {
  const token = Cookies.get("access_token").replace(/\./g, "token");

  const companyNameJSX = (
    <Link
      to={`${LANDING_PAGE_URL}/company/${log.company.uuid}/${token}`}
      target="_blank"
      style={{ fontWeight: "bold", color: "#30ACF1", display: "inline" }}
    >
      {log.company.companyName}
    </Link>
  );
  const candidateFullNameJSX = (
    <Link
      to={`${LANDING_PAGE_URL}/candidate/${log.user.uuid}/${token}`}
      target="_blank"
      style={{ fontWeight: "bold", color: "#30ACF1", display: "inline" }}
    >
      {log.user.firstName + " " + log.user.lastName}
    </Link>
  );

  if (log.collaborationStatus === "Pennding") {
    return (
      <>
        Company {companyNameJSX} has sent a collaboration request to{" "}
        {candidateFullNameJSX}
      </>
    );
  }

  if (log.collaborationStatus === "Accept") {
    return (
      <>
        Candidate {candidateFullNameJSX} has accepted the collaboration request
        from {companyNameJSX}
      </>
    );
  }
  if (log.collaborationStatus === "Reject") {
    return (
      <>
        Candidate {candidateFullNameJSX} has rejected the collaboration request
        from {companyNameJSX}
      </>
    );
  }
};
