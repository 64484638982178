import { ConfigProvider } from "antd";
import "antd/dist/antd.less";
import React, { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import ProtectedRoute from "./components/utilities/protectedRoute";
import config from "./config/config";
import store, { persistor } from "./redux/store";

import Admin from "./routes/admin";
import Auth from "./routes/auth";
import "./static/css/style.css";
import NotFound from "./container/pages/404";

const { themeColor } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.app.isLoggedIn,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <Router>
          {!isLoggedIn ? (
            <Routes>
              <Route path="/*" element={<Auth />} />
            </Routes>
          ) : (
            <Routes>
              <Route
                path="/admin/*"
                element={<ProtectedRoute path="/*" Component={Admin} />}
              />
              <Route path="*" element={<NotFound></NotFound>} />
            </Routes>
          )}
          {isLoggedIn && (
            <Routes>
              <Route path="/" element={<Navigate to="/admin" />} />
            </Routes>
          )}
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ProviderConfig />
      </PersistGate>
    </Provider>
  );
}

export default App;
