import { Route, Routes } from "react-router-dom";
import { JobPositions } from "../../container/pages/asset-management/JobPositions";

function FirebaseRoute() {
  return (
    <Routes>
      <Route path="/job-positions" element={<JobPositions />} />
      <Route path="/companies-in-platform" element={<div>COMING SOON</div>} />
    </Routes>
  );
}

export default FirebaseRoute;
