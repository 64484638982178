import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilSetting from "@iconscout/react-unicons/icons/uil-setting";
import UilSignout from "@iconscout/react-unicons/icons/uil-signout";
import UilUser from "@iconscout/react-unicons/icons/uil-user";
import { Avatar } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { InfoWraper, UserDropDwon } from "./auth-info-style";
import { logout } from "../../../redux/authentication/reducers"
import Heading from "../../heading/heading";
import { Popover } from "../../popup/popup";

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const SignOut = (e) => {
    e.preventDefault();

    dispatch(logout());
  };

  const { user } = useSelector((state) => {
    return {
      user: state.app.user,
    };
  });
  const path = "/admin";
  if (!user) {
    return null
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            src={require("../../../static/img/avatar/chat-auth.png")}
            alt=""
          />
          <figcaption>
            <Heading as="h5">{`${user.firstName} ${user.lastName}`}</Heading>
            <p>{user.jobPosition}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <NavLink to={`${path}/profile/myProfile/${user.uuid}`}>
              <UilUser /> Profile
            </NavLink>
          </li>
          <li>
            <Link to={`/admin/freelancers/edit/${user.uuid}`}>
              <UilSetting /> Settings
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            <span className="ninjadash-nav-actions__author--name">
              {user.username}
            </span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
