import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

import { getFromStore } from "../../helpers/getFromStore";

const initialState = {
  companies: null,
  isLoading: false,
  singleCompany: null,
  limitedCompanies: null,
  unlimitedCompanies: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCompanies.rejected, (state, action) => {
      state.companies = [];
      state.isLoading = false;
    });

    builder.addCase(fetchLimitedCompanies.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLimitedCompanies.fulfilled, (state, action) => {
      state.limitedCompanies = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchLimitedCompanies.rejected, (state, action) => {
      state.limitedCompanies = [];
      state.isLoading = false;
    });

    builder.addCase(fetchUnlimitedCompanies.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUnlimitedCompanies.fulfilled, (state, action) => {
      state.unlimitedCompanies = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchUnlimitedCompanies.rejected, (state, action) => {
      state.unlimitedCompanies = [];
      state.isLoading = false;
    });

    builder.addCase(fetchCompanyById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompanyById.fulfilled, (state, action) => {
      state.singleCompany = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCompanyById.rejected, (state, action) => {
      state.singleCompany = null;
      state.isLoading = false;
    });
  },
});

export const getCompany = async (id) => {
  const response = await getFromStore(
    "companies.company",
    id,
    async () => await DataService.get(`${API.company.user}/${id}`)
  );
  return response.data ?? response;
};

export const getCompanies = async (payload) => {
  const response = await DataService.get(API.company.user, payload);
  return response.data ?? response;
};

export const updateCompany = async (companyData) => {
  return await DataService.patch(
    `${API.company.user}/${companyData.uuid}`,
    companyData
  );
};

export const updateMaxProfileViews = async (companyId, maxProfileVisits) => {
  return await DataService.patch(
    `${API.company.updateMaxProfileViews}/${companyId}`,
    { maxProfileVisits: Number(maxProfileVisits) }
  );
};

export const deleteCompany = async (id) => {
  return await DataService.delete(`${API.company.user}/${id}`);
};

export const createCompany = async (companyData) => {
  return await DataService.post(API.company.user, companyData);
};

export const fetchCompanies = createAsyncThunk(
  "Company/fetchCompany",
  async (argh) => {
    return await getCompanies(argh);
  }
);

export const fetchCompanyById = createAsyncThunk(
  "Company/fetchCompanyById",
  async (argh) => {
    return await getCompany(argh);
  }
);

export const updateCompanies = createAsyncThunk(
  "Company/updateCompany",
  async (argh) => {
    return await updateCompany(argh);
  }
);

export const companyApproval = async (data) => {
  return await DataService.patch(`${API.company.approve}/${data.uuid}`, {
    accountStatus: data.status,
  });
};

export const fetchLimitedCompanies = createAsyncThunk(
  "Company/fetchLimitedCompanies",
  async (argh) => {
    return await getLimitedCompanies(argh);
  }
);

export const getLimitedCompanies = async (argh) => {
  const page = argh && argh.page ? `&page=${argh.page}` : "";
  const limit = argh && argh.limit ? `&limit=${argh.limit}` : "";

  const response = await getFromStore(
    "companies.limitedCompanies",
    undefined,
    async () =>
      await DataService.get(`${API.company.limitedCompanies}?${page}${limit}`)
  );
  return response.data ?? response;
};

export const fetchUnlimitedCompanies = createAsyncThunk(
  "Company/fetchUnlimitedCompanies",
  async (argh) => {
    return await getUnlimitedCompanies(argh);
  }
);

export const getUnlimitedCompanies = async (argh) => {
  const page = argh && argh.page ? `&page=${argh.page}` : "";
  const limit = argh && argh.limit ? `&limit=${argh.limit}` : "";

  const response = await getFromStore(
    "companies.unlimitedCompanies",
    undefined,
    async () =>
      await DataService.get(`${API.company.unlimitedCompanies}?${page}${limit}`)
  );
  return response.data ?? response;
};

export const CompanyReducer = companySlice.reducer;
