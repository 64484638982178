import { Spin } from "antd";
import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./dashboard";
import Features from "./features";
import Pages from "./pages";
import Users from "./users";
import Companies from "./company";
import withAdminLayout from "../../layout/withAdminLayout";
import { EarlyAccess } from "../../container/pages/early-access/EarlyAccess";
import { CollaborationsLogs } from "../../container/pages/logs/CollaborationsLogs";
import Manage from "./asset-management";

const KnowledgeBase = lazy(() =>
  import("../../container/pages/knowledgeBase/Index")
);
const AllArticle = lazy(() =>
  import("../../container/pages/knowledgeBase/AllArticle")
);
const KnowledgeSingle = lazy(() =>
  import("../../container/pages/knowledgeBase/SingleKnowledge")
);
const Myprofile = lazy(() => import("../../container/profile/myProfile/Index"));
const Companyprofile = lazy(() =>
  import("../../container/profile/myProfile/Company")
);
const Icons = lazy(() => import("./icons"));
const Freelancer = lazy(() => import("./freelancers"));
const Company = lazy(() => import("./companies"));
const AdminUsers = lazy(() => import("./admins"));
// const Payment = lazy(() => import("./payment"));
const NotFound = lazy(() => import("../../container/pages/404"));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="*" element={<Dashboard />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="all-articles" element={<AllArticle />} />
        <Route path="knowledgeBase/*" element={<KnowledgeBase />} />
        <Route path="knowledgebaseSingle/:id" element={<KnowledgeSingle />} />
        <Route path="users/*" element={<Users />} />
        <Route path="companies/*" element={<Companies />} />
        <Route path="features/*" element={<Features />} />
        <Route path="profile/myProfile/:id" element={<Myprofile />} />
        <Route path="profile/companyProfile/:id" element={<Companyprofile />} />
        <Route path="icons/*" element={<Icons />} />
        <Route path="freelancers/*" element={<Freelancer />} />
        <Route path="admins/*" element={<AdminUsers />} />
        {/* <Route path="payment/*" element={<Payment />} /> */}
        <Route path="all-companies/*" element={<Company />} />
        <Route path="logs/collaborations" element={<CollaborationsLogs />} />
        <Route path="early-access/*" element={<EarlyAccess />} />
        <Route path="manage/*" element={<Manage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
