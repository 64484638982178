import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

import { getFromStore } from "../../helpers/getFromStore";

const initialState = {
  freelancers: null,
  isLoading: false,
  singleFreelancer: null,
};

const freelancerSlice = createSlice({
  name: "freelancer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFreelancers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFreelancers.fulfilled, (state, action) => {
      state.freelancers = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchFreelancers.rejected, (state, action) => {
      state.freelancers = null;
      state.isLoading = false;
    });

    builder.addCase(fetchFreelancerById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFreelancerById.fulfilled, (state, action) => {
      state.singleFreelancer = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchFreelancerById.rejected, (state, action) => {
      state.singleFreelancer = null;
      state.isLoading = false;
    });
  },
});

export const getFreelancer = async (id) => {
  const response = await DataService.get(`${API.freelancer.user}/${id}`);
  return response.data ?? response;
};

export const getFreelancers = async (payload) => {
  const response = await DataService.get(API.freelancer.user, payload);
  return response.data ?? response;
};

export const updateFreelancer = async (freelancerData) => {
  return await DataService.patch(
    `${API.freelancer.user}/${freelancerData.uuid}`,
    freelancerData
  );
};

export const deleteFreelancer = async (id) => {
  return await DataService.delete(`${API.freelancer.user}/${id}`);
};

export const createFreelancer = async (freelancerData) => {
  return await DataService.post(API.freelancer.user, freelancerData);
};

export const fetchFreelancers = createAsyncThunk(
  "Freelancer/fetchFreelancer",
  async (argh) => {
    return await getFreelancers(argh);
  }
);

export const fetchFreelancerById = createAsyncThunk(
  "Freelancer/fetchFreelancerById",
  async (argh) => {
    return await getFreelancer(argh);
  }
);

export const updateFreelancers = createAsyncThunk(
  "Freelancer/updateFreelancer",
  async (argh) => {
    return await updateFreelancer(argh);
  }
);

export const freelancerApproval = async (data) => {
  return await DataService.patch(`${API.freelancer.approve}/${data.uuid}`, {
    accountStatus: data.status,
  });
};

export const FreelancerReducer = freelancerSlice.reducer;
