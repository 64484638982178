import Cookies from "js-cookie";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const initialState = {
  user: undefined,
  isLoggedIn: false,
  token: null,
  error: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = undefined;
      state.user = undefined;
      setTimeout(() => {
        Cookies.remove("access_token");
        window.location.href = "/";
      }, 100);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { accessToken } = action.payload;
      state.token = accessToken;
      state.isLoggedIn = true;
      state.error = null;
      Cookies.set("access_token", accessToken);
      Cookies.set("logedIn", true);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.token = undefined;
      state.isLoggedIn = false;
      state.error = "Your email address or password is incorrect !";
    });

    builder.addCase(fetchGetMe.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchGetMe.rejected, (state) => {
      state.user = undefined;
    });
  },
});

const getMe = async () => {
  return await DataService.get("/user/me");
};

const login_user = async (data) => {
  const response = await DataService.post(API.auth.login, data);
  return response;
};

export const login = createAsyncThunk("app/login", async (argh) => {
  const { data } = await login_user(argh);

  return data;
});

export const fetchGetMe = createAsyncThunk("app/me", async () => {
  const { data } = await getMe();
  return data;
});

const persistConfig = {
  key: "app",
  storage: storage,
  // Both commented means persist everything in this reducer
  // Do not persist this field
  blacklist: ["error"],
  // Persist only this field
};

export const { logout } = appSlice.actions;
export const AppReducer = persistReducer(persistConfig, appSlice.reducer);
