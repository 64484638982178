import { Main, TableWrapper } from "../../styled";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Col, Row, Spin, Table, Input } from "antd";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { useDispatch, useSelector } from "react-redux";
import { RecordViewWrapper } from "../../crud/companies/style";
import { useEffect } from "react";
import { getEarlyAccessCodes } from "../../../redux/early-access/reducers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "../../../components/buttons/buttons";
import { ModalStyled } from "../../../components/modals/styled";

import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { useState } from "react";
import { DataService } from "../../../config/dataService/dataService";
import { API } from "../../../config/api";

export const EarlyAccess = () => {
  const dispatch = useDispatch();
  const { earlyAccess, loading } = useSelector((state) => state.earlyAccess);

  const [username, setUsername] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    dispatch(getEarlyAccessCodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateNewEarlyAccessCode = async () => {
    if (!username) {
      return;
    }
    try {
      await DataService.post(API.earlyAccess.create, { username });
      await dispatch(getEarlyAccessCodes());
      setUsername("");
      closeModal();
    } catch (e) {
      toast.error("Couldn't create code");
    }
  };

  const rows =
    earlyAccess.length > 0
      ? earlyAccess.map((ea) => {
          const { username, code } = ea;

          return {
            key: username,
            username,
            code: (
              <div>
                {code.map((code) => (
                  <CopyToClipboard
                    text={code}
                    onCopy={() => toast.success("Code copied to clipboard!")}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        cursor: "pointer",
                      }}
                    >
                      {code}
                      <FeatherIcon
                        className="edit"
                        icon="clipboard"
                        size={14}
                      />
                    </div>
                  </CopyToClipboard>
                ))}
              </div>
            ),
          };
        })
      : [];

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      align: "left",
    },
  ];

  return (
    <RecordViewWrapper>
      <PageHeader
        className="ninjadash-page-header-main"
        routes={[]}
        buttons={[
          <Button
            className="btn-add_new"
            size="default"
            key="1"
            type="primary"
            onClick={openModal}
          >
            <FeatherIcon icon="plus" size={20} />
            <span>Add new code</span>
          </Button>,
        ]}
        ghost
        title="Early Access"
      />
      <Main>
        <Row gutter={15}>
          <Col className="w-100" md={24}>
            <Cards headless>
              {loading ? (
                <div className="spin">
                  <Spin />
                </div>
              ) : (
                <div>
                  <TableWrapper className="table-responsive">
                    <Table
                      dataSource={rows}
                      columns={columns}
                      pagination={false}
                    />
                  </TableWrapper>
                </div>
              )}
            </Cards>
          </Col>
        </Row>
      </Main>
      <ModalStyled
        title="Add new code"
        open={modalOpen}
        okText="Submit"
        onCancel={() => {
          setUsername("");
          closeModal();
        }}
        onOk={generateNewEarlyAccessCode}
      >
        <p>
          To create new early access code, enter the username for code and click
          submit
        </p>
        <Input
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
        />
      </ModalStyled>
    </RecordViewWrapper>
  );
};
