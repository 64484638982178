import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const initialState = {
  earlyAccess: [],
  statistics: [],
  loading: false,
  loadingStatistics: false,
};

const earlyAccessSlice = createSlice({
  name: "freelancer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEarlyAccessCodes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEarlyAccessCodes.fulfilled, (state, action) => {
      state.earlyAccess = action.payload;
      state.loading = false;
    });
    builder.addCase(getEarlyAccessCodes.rejected, (state) => {
      state.earlyAccess = [];
      state.loading = false;
    });

    builder.addCase(getEarlyAccessStatistics.pending, (state) => {
      state.loadingStatistics = true;
    });
    builder.addCase(getEarlyAccessStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload;
      state.loadingStatistics = false;
    });
    builder.addCase(getEarlyAccessStatistics.rejected, (state) => {
      state.statistics = [];
      state.loadingStatistics = false;
    });
  },
});

export const getEarlyAccessCodes = createAsyncThunk("earlyAcesss", async () => {
  const { data } = await DataService.get(API.earlyAccess.codes);
  return data;
});

export const getEarlyAccessStatistics = createAsyncThunk(
  "earlyAccessStatistics",
  async () => {
    const { data } = await DataService.get(API.earlyAccess.statistics);
    return data;
  }
);

export const earlyAccessReducer = earlyAccessSlice.reducer;
