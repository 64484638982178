/* eslint-disable */
import store from "../redux/store";

export const getFromStore = async (slice, id, refetch, force) => {
  const state = store.getState();

  if (!force || !state) {
    if (eval(`state.${slice}`)) {
      if (id) {
        return eval(`state.${slice}.data`).find((x) => x.id === id);
      } else {
        return eval(`state.${slice}.data`);
      }
    }
  }
  return refetch();
};
