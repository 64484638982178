import { combineReducers } from "redux";
import ChangeLayoutMode from "./themeLayout/reducers";
import { FreelancerReducer } from "./freelancers/reducers";
import { CompanyReducer } from "./companies/reducers";
import { AdminReducer } from "./admins/reducers";
import { PaymentReducer } from "./payment/reducers";
import { AppReducer } from "./authentication/reducers";
import { jobsListReducer } from "./jobPositions";
import { earlyAccessReducer } from "./early-access/reducers";
import { logsReducer } from "./logs";

const rootReducers = combineReducers({
  freelancers: FreelancerReducer,
  companies: CompanyReducer,
  admins: AdminReducer,
  payment: PaymentReducer,
  app: AppReducer,
  jobPositions: jobsListReducer,
  earlyAccess: earlyAccessReducer,
  logs: logsReducer,
  ChangeLayoutMode,
});

export default rootReducers;
